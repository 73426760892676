<template>
  <div>
    <vs-input
      v-model="email"
      name="email"
      icon-no-border
      icon="icon icon-user"
      icon-pack="feather"
      label-placeholder="Email"
      class="w-full"
    />
    <!-- <span class="text-danger text-sm">{{ errors.first('email') }}</span> -->

    <vs-input
      v-model="password"
      type="password"
      name="password"
      icon-no-border
      icon="icon icon-lock"
      icon-pack="feather"
      label-placeholder="Password"
      class="w-full mt-6"
    />
    <!-- <span class="text-danger text-sm">{{ errors.first('password') }}</span> -->

    <div class="flex flex-wrap justify-between mt-5">
      <vs-checkbox 
        v-model="checkbox_remember_me" 
        class="mb-3">Remember Me</vs-checkbox>
      <router-link to="/pages/forgot-password" class="text-red">Forgot Password?</router-link>
    </div>
    <!-- <vs-button type="border" @click="registerUser">Register</vs-button> -->
    <!-- <vs-button class="float-right" :disabled="!validateForm" @click="login"
      >Login</vs-button
    > -->
    <div class="flex flex-wrap justify-end mt-5">
      <vs-button 
        :disabled="!true" 
        class="float-right" 
        @click="login"
      >Login</vs-button
      >
    </div>
    <div class="flex flex-wrap mt-12">
      <span class="pr-1">คู่มือการเปลี่ยนรหัสผ่าน </span><a target="_blank" :href="`${imageUrl}/documentations/manual/cms/คู่มือการเปลี่ยนรหัสผ่าน_iacwxz.pdf`">ดาวน์โหลด</a>
    </div>
    <div class="flex flex-wrap justify-between mt-2">
      <span class="text-warning">หากติดปัญหาการใช้งานกรุณาแจ้ง <a href="mailto:webmaster@lh.co.th" class="text-warning">webmaster@lh.co.th</a></span>
    </div>
  </div>
</template>

<script>
import env from "@/env"
export default {
  data() {
    return {
      email: null,
      password: null,
      checkbox_remember_me: false,
    }
  },
  computed: {
    validateForm() {
      // return !this.errors.any() && this.email !== '' && this.password !== ''
      return ''
    },
    imageUrl() {
      const baseFolder = env.VUE_APP_CLOUDINARY_DEFAULT_FOLDER
      const baseImageUrl = env.VUE_APP_IMAGE_URL
      return `${baseImageUrl}/${baseFolder}`
    }
  },
  methods: {
    checkLogin() {
      // If user is already logged in notify
      if (this.$store.state.auth.isUserLoggedIn()) {
        // Close animation if passed as payload
        // this.$vs.loading.close()

        this.$vs.notify({
          title: 'Login Attempt',
          text: 'You are already logged in!',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
        })

        return false
      }
      return true
    },
    login() {
      // Loading
      localStorage.removeItem('userInfo')

      this.$vs.loading()

      const payload = {
        checkbox_remember_me: this.checkbox_remember_me,
        userDetails: {
          email: this.email,
          password: this.password,
        },
        notify: this.$vs.notify,
        closeAnimation: this.$vs.loading.close,
      }
      this.$store.dispatch('auth/loginAttempt', payload)
      .then(() => {
        // get user from local storage
        this.$router.push(this.$router.currentRoute.query.to || '/')
      })
      
    },

    // Google login
    loginWithGoogle() {
      this.$store.dispatch('auth/loginWithGoogle', { notify: this.$vs.notify })
    },

    // Facebook login
    loginWithFacebook() {
      this.$store.dispatch('auth/loginWithFacebook', { notify: this.$vs.notify })
    },

    // Twitter login
    loginWithTwitter() {
      this.$store.dispatch('auth/loginWithTwitter', { notify: this.$vs.notify })
    },

    // Github login
    loginWithGithub() {
      this.$store.dispatch('auth/loginWithGithub', { notify: this.$vs.notify })
    },

    registerUser() {
      if (!this.checkLogin()) return
      this.$router.push('/pages/register').catch(() => {})
    },
  },
}
</script>

<style lang="scss">
#page-login {
  .social-login-buttons {
    .bg-facebook {
      background-color: #1551b1;
    }
    .bg-twitter {
      background-color: #00aaff;
    }
    .bg-google {
      background-color: #4285f4;
    }
    .bg-github {
      background-color: #333;
    }
  }
}
.text-red {
  color:red;
}
</style>
